<template>
	<div class="equipment_view">
		<div class="equipment_view_top">
			<el-button @click="print" class="print_btn" round>
				<span>打印</span>
			</el-button>

			<span
				class="fanhui"
				@click="
					() => {
						$router.push({
							path: '/DataDocking/functionalMent',
							query: {
								type: 4,
							},
						})
					}
				"
			>
				<i class="iconfont iconfanhui"></i>返回
			</span>
			<p class="view_title"><span></span>基本信息</p>
			<el-form ref="form" :model="form" :rules="ruleForm" label-position="left" label-width="60px">
				<div class="flex_div">
					<el-form-item label="姓名" prop="name">
						<p>{{ form.username }}</p>
					</el-form-item>
					<el-form-item label="性别" prop="sex">
						<p>{{ form.sex }}</p>
					</el-form-item>
					<el-form-item label="年龄" prop="sex">
						<p>{{ form.age }}</p>
					</el-form-item>
					<el-form-item label="病人类型" prop="sex">
						<p>{{ form.person_type }}</p>
					</el-form-item>
				</div>
				<div class="flex_div">
					<el-form-item label="样本号" prop="type">
						<p>{{ form.specimen_no }}</p>
					</el-form-item>
					<el-form-item label="样本类型" prop="type">
						<p></p>
					</el-form-item>
					<el-form-item label="床号" prop="type">
						<p></p>
					</el-form-item>
					<el-form-item label="住院号" prop="type">
						<p></p>
					</el-form-item>
				</div>
				<div class="flex_div">
					<el-form-item label="科室" prop="type">
						<p></p>
					</el-form-item>
					<el-form-item label="费别" prop="type">
						<p></p>
					</el-form-item>
					<el-form-item label="诊断" prop="type">
						<p></p>
					</el-form-item>
					<el-form-item label="备注" prop="type">
						<p></p>
					</el-form-item>
				</div>
			</el-form>
		</div>
		<div class="equipment_view_bot">
			<p class="view_title"><span></span>检验报告</p>
			<el-table :data="form.infos" style="width: 100%">
				<el-table-column prop="parameter" label="代号" align="left">
					<template slot-scope="scope"> {{ scope.row.test_item }} </template>
				</el-table-column>
				<el-table-column prop="result" label="项目" align="center">
					<template slot-scope="scope"> {{ scope.row.test_xmmc }}</template>
				</el-table-column>
				<el-table-column prop="result" label="结果" align="center">
					<template slot-scope="scope">
						{{ scope.row.test_value }} <i v-if="scope.row.test_result == 1" class="el-icon-bottom" style="color: #FF8D03;font-size: 16px;"></i
						><i v-else-if="scope.row.test_result == 3" class="el-icon-top" style="color: #FF0000;font-size: 16px;"></i
					></template>
				</el-table-column>
				<el-table-column prop="test_dyckz" label="参考值" align="center">
					<template slot-scope="scope"> {{ scope.row.test_dyckz }} {{ scope.row.test_dw ? '*' : '' }} {{ scope.row.test_dw }}</template>
				</el-table-column>
			</el-table>
			<div class="image_view">
				<div v-for="(itm, idx) in form.img">
					<p>
						{{ idx }}
					</p>
					<img :src="'http://' + itm" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			form: {},
			imgData: [],
			ruleForm: {},
		}
	},
	mounted() {
		this.$axios
			.post(
				'/p/equipment_record/report',
				this.$qs({
					specimen_no: this.$route.query.specimen_no,
					test_date: this.$route.query.date,
				})
			)
			.then(res => {
				this.form = res.data.data
			})
	},
	methods: {
		print() {
			let routeUrl = this.$router.resolve({
				path: '/lookMentPrint',
				query: {
					specimen_no: this.$route.query.specimen_no,
					date: this.$route.query.date,
				},
			})
			window.open(routeUrl.href, '_blank')
		},
	},
}
</script>

<style lang="scss" scoped>
.equipment_view {
	background: #fff;
	padding: 20px 0;
	margin-bottom: 10px !important;
	.view_title {
		display: flex;
		align-items: center;
		font-size: 18px;
		color: #333;
		font-weight: 500;
		font-family: PingFangSC-Medium, PingFang SC;
		span {
			display: inline-block;
			width: 6px;
			height: 20px;
			background: #0055e9;
			border-radius: 3px;
			margin-right: 10px;
		}
	}
	.equipment_view_top {
		margin: 20px;
		position: relative;
		.print_btn {
			position: absolute;
			right: 100px;
			top: -27px;
			width: 120px;
			color: #fff;
			border: none;
			background: rgb(53, 200, 203);
		}
		.fanhui {
			font-size: 16px;
			color: #666;
			display: inline-block;
			position: absolute;
			right: 20px;
			top: -20px;
			i {
				margin-right: 5px;
				color: #000;
			}
			&:hover {
				cursor: pointer;
			}
		}
		.el-form {
			margin-top: 20px;
			margin-left: 20px;
			.flex_div {
				display: flex;
				align-items: center;
				.el-form-item {
					flex: 1;
					p {
						width: 80%;
						height: 40px;
						border-bottom: 1px solid #ccc;
					}
				}
			}
		}
	}
	.equipment_view_bot {
		margin: 40px 20px 0 20px;
		.el-table {
			margin-top: 20px;
		}
	}

	.image_view {
		display: flex;
		width: 100%;
		margin-top: 30px;
		justify-content: space-between;
		> div {
			width: 31%;
			height: auto;
			// border: 1px solid #ccc;
			p {
				text-align: center;
				font-size: 20px;
				line-height: 40px;
			}
			img {
				width: 100%;
				height: auto;
			}
			// box-shadow: 2px 2px 0px 0px #ccc;
		}
	}
}
</style>
